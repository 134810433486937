import React, { useState } from 'react'
import { Box, IconButton, Modal, Typography, Link, Button, Tooltip } from '@mui/material'
import { CopyClipboardIcon } from '../../../Assets/Images/svgs'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ErrorIcon from '@mui/icons-material/Error'
import mainApiService from '../../../Services'
import { PARAMETER_MANAGEMENT_CRUD } from '../../../Constants/ApiDefinations'
import { useAppDispatch } from '../../../Store/Hooks'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { useNavigate } from 'react-router-dom'
import { PAGES_ROUTES } from '../../../Constants'
import { CloneListingIcon, RedirectIcon } from '../../Icons'
import { handleResponse, openLinkInNewTab } from '../../../Helpers/Util'
import { showAlert } from '../../../Store/Slice/alert.slice'
import MUISelectWithoutControl from '../MUI-Select-Without-Control'

type clonePropBase = {
  cloneSegmemtIdNodeForApiPayload?: never
  apiMidPoint?: never
  redirectViewAPiRouteMidPoint?: string
  titleNodeToDisplay: string
  title?: string
  isCloneDisabled?: boolean
  openInNewTab?: boolean
}
type clonePropClonable = {
  cloneSegmemtIdNodeForApiPayload: string
  apiMidPoint: string
  redirectViewAPiRouteMidPoint: string
  titleNodeToDisplay: string
  title?: string
  isCloneDisabled?: boolean
  openInNewTab?: boolean
}
type cloneProp = clonePropBase | clonePropClonable
interface CloneModalProps {
  id: string
  onCancel?: (value: boolean) => void
  cloneProps?: cloneProp
}

const CloneModal: React.FC<CloneModalProps> = ({ id, cloneProps, onCancel }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [openCloneModal, setOpenCloneModal] = useState(false)
  const [openClonedModal, setOpenClonedModal] = useState(false)
  const [openFailedModal, setOpenFailedModal] = useState(false)
  const [error, setError] = useState<string>('')
  const [numberOfClones, setNumberOfClones] = useState(1)
  const [clonedItems, setClonedItems] = useState<any[]>([])
  const handleCloneClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpenCloneModal(true)
  }
  const handleCloneSubmit = async () => {
    try {
      const data = {
        [`${cloneProps?.cloneSegmemtIdNodeForApiPayload}`]: id,
        noOfCopies: numberOfClones,
      }
      const requestedData: any = PARAMETER_MANAGEMENT_CRUD.CLONE_PARAMETER(
        data,
        cloneProps?.apiMidPoint,
      )
      const result = await mainApiService(requestedData)
      const { message, error, responseCode } = handleResponse(result)
      if (error) {
        setOpenCloneModal(false)
        setOpenFailedModal(true)
        setError(message)
        dispatch(showAlert([true, message, 'error']))
      } else {
        dispatch(setLoader(false))
        setError('')
        dispatch(showAlert([true, message, 'success']))
        setOpenCloneModal(false)
        setOpenClonedModal(true)
        setClonedItems(result?.data)
      }
    } catch (error: any) {
      console.error('Error cloning items:', error)
      setError('Error cloning items')
    }
  }

  const isCloneDisabled = cloneProps?.isCloneDisabled || false
  const options = Array.from({ length: 10 }, (_, i) => ({ value: i + 1, label: i + 1 }))
  const handleCancel = () => {
    setOpenClonedModal(false)
    if (onCancel) {
      onCancel(true)
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Tooltip
        title={
          !isCloneDisabled
            ? ''
            : `Add the 'Title' to this ${cloneProps?.title ?? ''}, as it is required for cloning`
        }
        arrow
        disableInteractive={!isCloneDisabled}
      >
        <span>
          <IconButton
            data-testid='cloneBtn'
            onClick={handleCloneClick}
            disabled={isCloneDisabled}
            className={isCloneDisabled ? 'disabled' : ''}
          >
            <CloneListingIcon />
          </IconButton>
        </span>
      </Tooltip>
      {/* Clone Modal */}
      <Modal open={openCloneModal} onClose={() => setOpenCloneModal(false)}>
        <Box sx={{ ...modalStyle }}>
          <div className='clone-icon'>
            <CopyClipboardIcon />
          </div>
          <Typography variant='h6'>Clone {cloneProps?.title ? cloneProps?.title : ''}</Typography>
          <Typography style={{ paddingBottom: 30, fontSize: 13 }}>
            Enter the number of clones you want to create for this{' '}
            {cloneProps?.title ? cloneProps?.title : ''}
          </Typography>
          <MUISelectWithoutControl
            sx={{ height: '48px' }}
            lable={`No. of Clone ${cloneProps?.title ? cloneProps?.title : ''}`}
            options={options}
            value={numberOfClones}
            onChange={(value: React.SetStateAction<number>) => setNumberOfClones(value)}
          />
          <Box sx={{ width: '100%', display: 'flex', gap: 2, height: '44px' }} mt={4}>
            <Button
              variant='outlined'
              fullWidth
              onClick={() => setOpenCloneModal(false)}
              className='clone-modal-text'
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              fullWidth
              onClick={handleCloneSubmit}
              className='clone-modal-text'
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      {error && (
        <div className='mt-1 text-left' data-testid='parentId-json__error'>
          <div className='d-flex col37 paragraph-6'>
            <ErrorOutlineOutlinedIcon
              sx={{ color: '#d4351c', fontSize: '1.25rem', marginRight: '.675rem' }}
            />{' '}
            {error}
          </div>
        </div>
      )}
      {/* Cloned Items Modal */}
      <Modal open={openClonedModal} onClose={() => setOpenClonedModal(false)}>
        <Box
          sx={{ ...modalStyle, height: 411 }}
          overflow={clonedItems?.length > 4 ? 'scroll' : 'auto'}
        >
          <div className='clone-icon'>
            <CopyClipboardIcon />
          </div>
          <Typography variant='h6'>Cloned {cloneProps?.title ? cloneProps?.title : ''}</Typography>
          <Typography className='cloned-text'>
            The {cloneProps?.title ? cloneProps?.title : ''} has been successfully cloned. Below are
            the links to the cloned {cloneProps?.title ? cloneProps?.title : ''}
          </Typography>
          <h5 className='cloned-title'>
            List of Cloned {cloneProps?.title ? cloneProps?.title : ''}
          </h5>
          {clonedItems?.map((item, index) => (
            <Typography key={index} className='clone-list'>
              <Link
                onClick={() => {
                  const baseUrl = `/${PAGES_ROUTES.BoltMasterNew}/${cloneProps?.redirectViewAPiRouteMidPoint}/view/${item._id}`
                  const fallbackUrl = `/${cloneProps?.redirectViewAPiRouteMidPoint}/view/${item._id}`

                  if (cloneProps?.openInNewTab) {
                    openLinkInNewTab(fallbackUrl)
                  } else {
                    navigate(baseUrl)
                  }
                }}
              >
                <span>
                  {`${index + 1}. `}
                  {item[cloneProps?.titleNodeToDisplay ?? '']}
                </span>
                <RedirectIcon />
              </Link>
            </Typography>
          ))}
          <Box mt={4}>
            <Button
              variant='outlined'
              className='clone-modal-text'
              fullWidth
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openFailedModal} onClose={() => setOpenFailedModal(false)}>
        <Box sx={{ ...modalStyle, height: 200 }}>
          <ErrorIcon style={{ fill: '#D4351C' }} />
          <Typography variant='h6'>
            Cloning of {cloneProps?.title ? cloneProps?.title : 'item'} Failed
          </Typography>

          <Box mt={4}>
            <Button
              variant='outlined'
              className='cloned-btn'
              fullWidth
              onClick={() => {
                setOpenFailedModal(false)
                window.location.reload()
              }}
            >
              Reload
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
// Modal styling
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 355,
  borderRadius: 3,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}
export default CloneModal
