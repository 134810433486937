import { format, getTime } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { jwtDecode } from 'jwt-decode'
import CONSTANTS, { PAGES_ROUTES } from '../Constants'
import { AUTH_MANAGEMENT } from '../Constants/ApiDefinations'
import { MASTER_MANAGEMENT_CONSTANTS } from '../Constants/Constant/Masters'
import { TypeObject, roleModulesModal } from '../Models'
import mainApiService from '../Services'
import { store } from '../Store'
import { showAlert } from '../Store/Slice/alert.slice'
// import { generatePresignedUrlForSubtitle } from '../Store/Slice/orchestration.slice'
import { CATEGORY_LABEL, FORM_FIELD } from '../Constants/ManageWidget'
import {
  getMastersData,
  getParameterRepositoryDetail,
} from '../Store/Slice/data-engine-category.slice'
import { setAuthConfig } from '../Store/Slice/user.slice'
import { getLocalStorage, setLocalStorage } from './Storage'
import { FieldFunctions } from './constant'
import { Dispatch } from '@reduxjs/toolkit'
import { setHookFormErrors } from '../Store/Slice/masters.slice'
import { setLoader } from '../Store/Slice/loader.slice'

export const getStatus = (status: any) => {
  const statusMap: Record<string, string> = {
    [CONSTANTS.ASSET_STATUS.CREATED]: 'Draft-Created',
    [CONSTANTS.ASSET_STATUS.DRAFT]: 'Draft',
    [CONSTANTS.ASSET_STATUS.DRAFT_CREATE]: 'Draft Create',
    [CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE]: 'Draft Complete',
    [CONSTANTS.ASSET_STATUS.DRAFT_UNDER_QC]: 'Draft Under QC',
    [CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_FEATURING]: 'Draft - Ready for Featuring',
    [CONSTANTS.ASSET_STATUS.SCHEDULE_FEATURE]: 'Scheduled For Featured',
    [CONSTANTS.ASSET_STATUS.FAILED]: 'Failed',
    [CONSTANTS.ASSET_STATUS.PUBLISH_FAIL]: 'Publishing Failed',
    [CONSTANTS.ASSET_STATUS.FEATURED]: 'Featured',
    [CONSTANTS.ASSET_STATUS.ARCHIEVED]: 'Archived',
    [CONSTANTS.ASSET_STATUS.DE_PUBLISH]: 'Depublished',
    [CONSTANTS.ASSET_STATUS.UNFEATURED]: 'Un Featured',
    [CONSTANTS.ASSET_STATUS.RIGHTS_EXPIRED]: 'Rights Expired',
    [CONSTANTS.ASSET_STATUS.DRAFT_CREATE]: 'Draft Create',
    [CONSTANTS.ASSET_STATUS.PUBLISHED]: 'Published',
  }

  return statusMap[status] || status || ''
}

export const getAssetStatusBackgroundColor = (status: any) => {
  const colorMap: Record<string, string> = {
    [CONSTANTS.ASSET_STATUS.CREATED]: '#0073A1',
    [CONSTANTS.ASSET_STATUS.DRAFT]: '#9F760D',
    [CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE]: '#5C4200',
    [CONSTANTS.ASSET_STATUS.DRAFT_UNDER_QC]: '#402080',
    [CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_FEATURING]: '#160B2D',
    [CONSTANTS.ASSET_STATUS.SCHEDULE_FEATURE]: '#0073A1',
    [CONSTANTS.ASSET_STATUS.FAILED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.PUBLISH_FAIL]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.FEATURED]: '#005A30',
    [CONSTANTS.ASSET_STATUS.ARCHIEVED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.DE_PUBLISH]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.UNFEATURED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.RIGHTS_EXPIRED]: '#D4351C',
  }

  return colorMap[status] || '#E6F7FD'
}
export const stringToHexColor = (str: string) => {
  let hashCode = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hashCode = (hashCode << 5) - hashCode + char
    hashCode &= hashCode
  }

  const colorCode = '#' + (hashCode & 0xffffff).toString(16).padStart(6, '0')

  return colorCode
}
export const getContentCategoryColor = (status: any) => {
  const colorMap: Record<string, string> = {
    ['TV_SHOW']: '#7CAB13',
    ['ORIGINALS']: '#F96E5A',
    ['MOVIES']: '#7557FB',
    ['NON_VOD_ASSETS']: '#FFB26A',
    ['SPORTS']: '#DE76C1',
    ['INDEPENDENT_VOD_ASSETS']: '#79DEFE',
  }

  return colorMap[status] || stringToHexColor(status)
}

export const getMetaCategoryName = (status: any) => {
  const statusMap: Record<string, string> = {
    ['TV_SHOW']: 'TV Shows',
    ['ORIGINALS']: 'Originals',
    ['MOVIES']: 'Movies',
    ['SPORTS']: 'Sports',
    ['INDEPENDENT_VOD_ASSETS']: 'Independent VOD Assets',
    ['NON_VOD_ASSETS']: 'Non VOD Assets',
  }

  return statusMap[status] || status || ''
}
export const getMetaCategoryNameHomepage = (status: any) => {
  const statusMap: Record<string, string> = {
    ['TV_SHOW']: 'TV Shows',
    ['ORIGINALS']: 'Originals',
    ['MOVIES']: 'Movies',
    ['SPORTS']: 'Sports',
    ['INDEPENDENT_VOD_ASSETS']: 'Independent VOD',
    ['NON_VOD_ASSETS']: 'Non VOD',
  }

  return statusMap[status] || status || ''
}
export const toMilliseconds = (hrs: number, min: number, sec: number) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000

export const setToken = (token: string) => {
  const decoded = jwtDecode(token)
  const { exp = 0 } = decoded || {}
  const expireTimeInMiliSec = exp * 1000
  const authConfig: any = {
    token: token,
    expireTime: expireTimeInMiliSec,
  }
  setLocalStorage(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG, authConfig)
  store.dispatch(setAuthConfig(authConfig) as any)
  setRefreshTokenCallback()
}

export const setRefreshTokenCallback = () => {
  const token: { token: string; expireTime: number } = JSON.parse(
    localStorage.getItem(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG) as string,
  )
  if (token?.expireTime) {
    const expireTimeInMiliSec = new Date(token?.expireTime).setMinutes(
      new Date(token.expireTime).getMinutes() - 5,
    )
    if (new Date(expireTimeInMiliSec) < new Date()) refreshToken()
  }
}

export const refreshToken = async () => {
  const { data = {} } = await mainApiService(AUTH_MANAGEMENT.REFRESH_TOKEN())
  const { token = '' } = data
  if (token) setToken(token)
}

export const getDateInFormat = (date: string, dFormat: string) => {
  if (date === 'NA') return 'NA'
  if (date) return format(new Date(date), dFormat)
  return ''
}
export const convertToUTC = (localDate: string, timeZone: string) => {
  if (!localDate || localDate === 'NA') return 'NA'
  const utcDate = zonedTimeToUtc(localDate, timeZone)
  return utcDate.toISOString()
}

export const isAtLeastOneTrue = (arr: roleModulesModal[]) => {
  for (const module of arr) {
    for (const submodule of module.submodules) {
      const permissions = submodule.permission
      if (Object.values(permissions).some((value) => value === true)) {
        return true
      }
    }
  }
  return false
}

export const isAtLeastOneSelectedPerModule = (arr: roleModulesModal[]) => {
  const hasTruePermission = (submodules: any[]) => {
    for (const submodule of submodules) {
      const permissions = submodule.permission
      if (Object.values(permissions).some((value) => value === true)) {
        return true
      }
    }
    return false
  }
  for (const module of arr) {
    if (hasTruePermission(module.submodules)) {
      return true
    }
  }
  return false
}
export const getWidgetTypeText = (widgetType: any) => {
  if (widgetType.isSpotLightLayout) {
    return 'Items'
  }
  if (
    widgetType.isFilteBasedLayoutLandscape ||
    widgetType.isFilteBasedLayoutPotrait ||
    widgetType.isFilteBasedLayoutSquare
  ) {
    return 'Widgets'
  }
  if (widgetType.isCategoryPageLayout) {
    return 'Containers'
  }
  if (widgetType.isCardSkinnedVideoLayout) {
    return 'Asset'
  }
  return 'Assets'
}
export const objectToQueryString = (obj: any): any => {
  const keys = Object.keys(obj)
  const keyValuePairs = keys.map((key) => {
    if (obj[key]) {
      return (key + '=' + obj[key]) as string | number | boolean
    }
  })

  const filteredArray = keyValuePairs.filter((element) => element !== undefined)
  return filteredArray.join('&')
}
export function convertHyphenatedString(input: string) {
  return input
    .toLowerCase()
    .split('-')
    .map((word: string, index: number) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join('')
}
export const getPageTitle = (id: string) => {
  const { VIEW, EDIT, CREATE } = CONSTANTS.PAGE_TYPE
  switch (id) {
    case VIEW as string:
      return VIEW
    case CREATE:
      return CREATE
    default:
      return EDIT
  }
}

export const getSubstring = (value: string, limit: number) => {
  return value?.length > limit ? `${value?.substring(0, limit)}...` : value
}

export const getStringLimit = (value: string, limit: number): string => {
  if (value?.length > limit) {
    return value
  } else return ''
}

export const combineErrorMessage = (unwrappableRes: any) => {
  if (Array.isArray(unwrappableRes?.error?.errors)) {
    if (unwrappableRes?.error?.errors?.length > 0) {
      return unwrappableRes?.error?.errors.join(',\n')
    }
    if (unwrappableRes?.error?.data?.length > 0) {
      return unwrappableRes?.error?.data.join(',\n')
    }
  }

  return unwrappableRes?.error?.message || 'Something went wrong'
}

export const combineErrors = (errors: any) => {
  if (Array.isArray(errors)) {
    if (errors?.length > 0) {
      return errors.join(',\n')
    } else {
      return 'Something went wrong'
    }
  } else {
    return 'Something went wrong'
  }
}

export const handleApiError = (dispatch: Dispatch, result: { error?: { errors: any[] } }) => {
  const toastMessages =
    result?.error?.errors
      .filter((item: any) => item.includes('toastMessage'))
      .map((item: any) => item.replace(/["']?toastMessage["']?\s*/i, '').trim()) || []

  if (toastMessages.length > 0) {
    dispatch(showAlert([true, combineErrors(toastMessages), 'error']))
  }
  dispatch(setHookFormErrors(result?.error?.errors))
  dispatch(setLoader(false))
}

export const isValidObject = (obj: any) => {
  if (obj) {
    return Object.keys(obj).length > 0 ? true : false
  }
  return false
}

export const getValueFromObjArr = (obArr: any[], key: string) => {
  let value = ''
  if (obArr?.length > 0 && key) {
    obArr?.forEach((obj: any) => {
      if (isValidObject(obj) && obj?.key === key) {
        value = obj?.value
      }
    })
  }
  return value ? value : ''
}
export function trimStrings(value: any): any {
  // If the value is a string, trim it and return it
  if (typeof value === 'string') {
    return value.trim()
  }
  // If the value is an array, recursively trim its elements
  if (Array.isArray(value)) {
    return value.map(trimStrings)
  }
  // If the value is an object, recursively trim its properties
  if (typeof value === 'object' && value !== null) {
    // Create a new object to store the trimmed values
    const trimmedObj: any = {}
    // Loop through each key in the object
    for (const key in value) {
      // Ensure the property is directly on the object and not inherited
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        trimmedObj[key] = trimStrings(value[key])
      }
    }
    return trimmedObj
  }
  // For other types, just return the value as is
  return value
}

export const createAttributePayload = (attributeFormData: any, type: string) => {
  const tmpArr: any[] = []
  attributeFormData?.attributeRef?.forEach((item: any, index: number) => {
    tmpArr.push({
      _id: item?._id,
      sortOrder: index + 1,
    })
  })

  let affiliatePartner = []
  if (attributeFormData?.affiliatePartner?.length > 0) {
    affiliatePartner = attributeFormData?.affiliatePartner.map((x: any) => x.key)
  }

  const defaultPayload = {
    fieldType: attributeFormData?.fieldType ? attributeFormData?.fieldType : undefined,
    dataType: attributeFormData?.dataType ? attributeFormData?.dataType : undefined,
    fieldName: attributeFormData?.fieldName ? attributeFormData?.fieldName?.trim() : undefined,
    isRequired: attributeFormData?.isRequired
      ? attributeFormData?.isRequired === 'Yes'
        ? true
        : false
      : undefined,
    isMultiple: attributeFormData?.isMultiple
      ? attributeFormData?.isMultiple === 'Yes'
        ? true
        : false
      : undefined,
    label: attributeFormData?.label ? attributeFormData?.label : undefined,
    isB2B: attributeFormData?.isB2B
      ? attributeFormData?.isB2B === 'Yes'
        ? true
        : false
      : undefined,
    defaultValue: attributeFormData?.defaultValue ? attributeFormData?.defaultValue : '',
    affiliatePartner: attributeFormData?.affiliatePartner?.length > 0 ? affiliatePartner : [],
    placeholder: attributeFormData?.placeholder ? attributeFormData?.placeholder : undefined,
    master: attributeFormData?.master ? attributeFormData?.master : undefined,
    options: attributeFormData?.dataType ? attributeFormData?.dataType : undefined,
    addMore: attributeFormData?.addMore
      ? attributeFormData?.addMore === 'Yes'
        ? true
        : false
      : undefined,
    status: attributeFormData?.status ? attributeFormData?.status : undefined,
    isMultilingual: attributeFormData?.isMultilingual
      ? attributeFormData?.isMultilingual === 'Yes'
        ? true
        : false
      : undefined,
    esField: attributeFormData?.esField ? attributeFormData?.esField : null,
    description: attributeFormData?.description ? attributeFormData?.description : undefined,
    additionalProperties: attributeFormData?.additionalProperties
      ? JSON.parse(attributeFormData?.additionalProperties)
      : null,

    attributeRef: tmpArr,
    metaCategory: 'NA',
    validations: {},
  }
  if (type === CONSTANTS.PAGE_TYPE.CREATE) {
    const reqData = {
      ...defaultPayload,
      options: attributeFormData?.options?.split(',') || [],
    }
    return reqData
  }
  if (type === CONSTANTS.PAGE_TYPE.EDIT) {
    let options = []
    if (Array.isArray(attributeFormData?.options)) {
      options = attributeFormData?.options?.toString()
      options = options.split(',')
    } else {
      options = attributeFormData?.options?.split(',') || []
    }
    const reqData = {
      ...defaultPayload,
      options: options,
    }
    return reqData
  }
  return {}
}

export const removeDuplicateAttributes = (arr: any[]) => {
  const uniqueArr: any[] = []
  arr?.forEach((x: any) => {
    const index = uniqueArr.findIndex((y: any) => y?.fieldName === x?.fieldName)
    if (index < 0) {
      uniqueArr.push(x)
    }
  })
  return uniqueArr
}

export const isValidArray = (array: any[]) => {
  return typeof array === 'object' && typeof array !== 'string' && array?.length > 0 ? true : false
}

export const isValidJSON = (str: string) => {
  /*
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
  */
  try {
    const o = JSON.parse(str)
    if (o && typeof o === 'object') return true
    return false
  } catch (e) {
    return false
  }
}

// delete element from object if its null/undefined/empty
export const ValidateObject = (Obj: any) => {
  try {
    Object.keys(Obj).forEach((key) => {
      if (Obj[key] === null || Obj[key] === undefined || Obj[key] === '') {
        delete Obj[key]
      }
    })
    return Obj
  } catch (e) {
    return {}
  }
}

export const getSystemConfigurationMasterObject = (systemConfigurationPageType: string) => {
  let result: any = null
  MASTER_MANAGEMENT_CONSTANTS.SYSTEM_CONFIGURATION_LIST?.forEach((sc: any) => {
    const { url = '', key = '', name = '' } = sc
    if (url && key && name) {
      const splittedURL = url?.split('/')
      const pageType = splittedURL[splittedURL?.length - 1]
      if (systemConfigurationPageType === pageType) {
        result = sc
      }
    }
  })
  return result
}

export const emptyFunction = () => {
  return
}

export const getMasterPayload = (masterKey: string, obj: any) => {
  const body = {
    configKey: masterKey,
    configValue: {
      key: obj?.key || '',
      value: obj?.value || '',
    },
  }
  return body
}

const replacer = (match: any, pIndent: any, pKey: any, pVal: any, pEnd: any) => {
  const key = '<span class=json-key>'
  const val = '<span class=json-value>'
  const str = '<span class=json-string>'
  let r = pIndent || ''
  if (pKey) r = r + key + pKey.replace(/[": ]/g, '') + '</span>: '
  if (pVal) r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>'
  return r + (pEnd || '')
}

export const prettyPrint = (obj: any) => {
  const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm
  return JSON.stringify(obj, null, 3)
    .replace(/&/g, '&amp;')
    .replace(/\\"/g, '&quot;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(jsonLine, replacer)
}

export const updatingSortOrderValue = (array: any[]) => {
  const copyArr = JSON.parse(JSON.stringify(array))
  copyArr?.forEach((item: any, index: number) => {
    item.sortOrder = index
  })
  return copyArr
}

export const uploadInPresignedURL = async (
  presignedUrl: any,
  file: any,
  dispatch: any,
  uploadFileName: any,
) => {
  try {
    if (!presignedUrl) return ''
    if (!file) {
      dispatch(showAlert([true, 'Please select a file', 'error']))
      return
    }
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
    })
    if (response.ok) {
      return uploadFileName
    } else {
      dispatch(showAlert([true, 'Upload Failed', 'error']))
      return ''
    }
  } catch (error) {
    dispatch(showAlert([true, 'Upload Failed', 'error']))
    return ''
  }
}

// export const uploadSubtitleToS3 = async (
//   dispatch: any,
//   uploadFileName: string,
//   file: any,
//   contentId: string,
// ) => {
//   try {
//     let reqUrl: any = ''
//     const result: any = dispatch(
//       generatePresignedUrlForSubtitle(`key=${uploadFileName}&contentId=${contentId}`) as any,
//     )
//     const unwrapRes = unwrapResult(await result)
//     if (unwrapRes?.error) {
//       dispatch(showAlert([true, combineErrorMessage(unwrapRes), 'error']))
//       return reqUrl
//     }
//     const resUrl = unwrapRes?.data?.url || ''
//     const fileName = await uploadInPresignedURL(resUrl, file, dispatch, uploadFileName)
//     reqUrl = fileName ? `${CONSTANTS.S3_SUBTITLE_BUCKET_BASE_URL}/${fileName}` : ''
//     return reqUrl
//   } catch (error: any) {
//     console.log('error', error)
//     return ''
//   }
// }

export const downloadFile = async (dispatch: any, path: string, markAsDoneDetail: any) => {
  try {
    let id = markAsDoneDetail?.legacyHouseId
    if (!id) {
      id = markAsDoneDetail?.blitzId
    }
    const url = `${CONSTANTS.CLOUDFRONT_BASE_URL_SUBTITLE}/subtitles/${id}/${path}`
    const res: any = await fetch(url)
    const blob: any = await res.blob()

    if (blob) {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${path}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (error: any) {
    dispatch(showAlert([true, `'Error downloading file:' ${error}`, 'error']))
  }
}

export const checkModuleAccess = (role: string | undefined) => {
  let result = true
  if (role) {
    const roleData: any = getLocalStorage(CONSTANTS.LOCAL_KEYS.ROLE_DATA)
    for (let i = 0; i < roleData?.length; i++) {
      for (let j = 0; j < roleData[i]?.submodules?.length; j++) {
        if (roleData[i]?.submodules[j]?.key === role) {
          result = roleData[i]?.submodules[j]?.permission?.canAccess
          break
        }
      }
    }
  }
  return result
}
export const getCapitalise = (value: string) => {
  return value?.charAt(0)?.toUpperCase() + value?.replace('_', ' ')?.slice(1)?.toLowerCase()
}

export const convertSecondsToHours = (seconds: number) => {
  const days = Math.floor(seconds / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const remainingSeconds = seconds % 3600
  const minutes = Math.floor(remainingSeconds / 60)
  const remSeconds = remainingSeconds % 60
  const getValue = (value: number, key: string) => {
    return `${value} ${key}${value > 1 ? 's' : ''}`
  }
  if (days > 0) {
    return `${getValue(days, 'day')}, ${getValue(hours, 'hour')}, ${getValue(
      minutes,
      'minute',
    )}, ${getValue(remSeconds, 'second')}`
  } else if (hours > 0) {
    return `${getValue(hours, 'hour')}, ${getValue(minutes, 'minute')}, ${getValue(
      remSeconds,
      'second',
    )}`
  } else if (minutes > 0) {
    return `${getValue(minutes, 'minute')}, ${getValue(remSeconds, 'second')}`
  } else if (remSeconds > 0) {
    return `${getValue(remSeconds, 'second')}`
  }
  return 'NA'
}

export const fetchOptionsValue = (options: any, value: string) => {
  if (options && options.length) {
    const displayObj = options.find((item: any) => item.key === value)
    return displayObj?.value ?? ''
  }
  return ''
}

export const getValueBasedOnString = (
  value: any,
  fieldType: string,
  tagInput: boolean,
  dataType: string,
  element: {
    attribute?: {
      dataType?: string
      options?: {
        value: any
        label: string
      }[]
    }
  },
): string => {
  if (fieldType !== CONSTANTS.FIELDTYPES.FILE) {
    if ((typeof value !== 'boolean' && !value) || (Array.isArray(value) && value.length === 0)) {
      return 'NA'
    }
    if (
      tagInput ||
      (fieldType === CONSTANTS.FIELDTYPES.SELECT &&
        dataType === CONSTANTS.ASSET_CREATION_OBJ.BOOLEAN)
    ) {
      if (Array.isArray(value)) {
        return value.join(', ')
      } else {
        return typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value
      }
    } else if (fieldType === CONSTANTS.FIELDTYPES.SELECT) {
      if (Array.isArray(value)) {
        return value
          .map((item: any) => fetchOptionsValue(element?.attribute?.options, item))
          .join(', ')
      } else {
        return fetchOptionsValue(element?.attribute?.options, value)
      }
    } else if (
      fieldType === CONSTANTS.FIELDTYPES.DATE ||
      fieldType === CONSTANTS.FIELDTYPES.DATETIME ||
      fieldType === CONSTANTS.FIELDTYPES.TIME
    ) {
      if (dataType === CONSTANTS.FIELDTYPES.NUMBER) {
        return convertSecondsToHours(value)
      }
      return getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME)
    } else if (
      fieldType === CONSTANTS.FIELDTYPES.CHECKBOX ||
      fieldType === CONSTANTS.FIELDTYPES.RADIO ||
      fieldType === CONSTANTS.FIELDTYPES.SWITCH
    ) {
      return value ? 'Yes' : 'No'
    } else {
      return value
    }
  }
  return ''
}

export const subStringWithEllipses = (value: string, count = 50, elipses = true): string => {
  if (value.length <= count) {
    return value
  }
  const truncatedString = value.substring(0, count)
  return elipses ? `${truncatedString}...` : truncatedString
}
// Utilized it if you need temporary unique id
export const uniqueId = () => {
  return Math.random() + getTime(new Date())
}

export const replaceUnwanted = (data: string) => {
  return data?.replace(/[^A-Z0-9]+/gi, '_')?.toLowerCase()
}
export const filterObject = <T extends Record<string, string | number>>(obj: T): T => {
  const result: Partial<T> = {}
  for (const key in obj) {
    if (
      obj[key] !== 0 &&
      obj[key] !== '' &&
      key !== 'filterCondition' &&
      key !== 'filterValue' &&
      key !== 'filterBy'
    ) {
      result[key] = obj[key]
    }
  }

  return result as T
}
export const getCurrentStatus = (status: string) => {
  switch (status) {
    case 'PREVIEW_INITIATED':
      return 'Preview request in-progress'
    case 'TRANSCODING_INITIATED':
      return 'Transcoding in-progress'
    case 'VERIFICATION_INITIATED':
      return 'Fetching in-progress'
    default:
      return ''
  }
}

export const getSuccessStatus = (state: string) => {
  switch (state) {
    case 'validation':
      return 'Fetching Successful'
    case 'transcoding':
      return 'Transcoding Success'
    case 'preview':
      return 'Preview Request Successful'
    default:
      return `${state} is Successfully Done`
  }
}

export const changeToCapitalCase = (value: string) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}

/**
 * Function to return parsed data of JWT token in the form of object.
 * @function getParsedToken
 * @returns {object} JWT parsed object which is parsed from token stored in localstorage
 */
export const getParsedToken = () => {
  const authConfig: { token: string } = getLocalStorage('auth_config')
  if (authConfig?.token) {
    const parsedToken = jwtDecode(authConfig.token)
    return parsedToken
  }
}

export const logoutCyberArk = (message = '') => {
  if (message) {
    setLocalStorage(CONSTANTS.LOCAL_KEYS.LOGOUT_MESSAGE, { message })
  }
  const authToken: any = getLocalStorage(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG)
  if (authToken && authToken.token) {
    const parsedToken: { sub: { accessToken: string } } = jwtDecode(authToken.token)
    if (parsedToken?.sub?.accessToken) {
      const redirectUrl = `${CONSTANTS.SESSION_END_URL}?id_token_hint=${parsedToken?.sub
        ?.accessToken}&post_logout_redirect_uri=${`${window.location.origin}/login`}`
      window.location.href = redirectUrl
    }
  } else {
    window.location.href = PAGES_ROUTES.LoginPage
  }
}

export const logout = async () => {
  const { responseCode, message, error = {} } = await mainApiService(AUTH_MANAGEMENT.LOGOUT())
  if (responseCode === 200) {
    logoutCyberArk(message)
    return
  }
  if (error) store.dispatch(showAlert([true, error?.message, 'error']))
}
export const getSelectedAttributeDetails = (selectedAttri: any, attributes: any) => {
  const matchingObjects = []

  for (const selectedObj of selectedAttri) {
    const matchingDataObject = attributes.find((ele: any) => ele._id === selectedObj._id)

    if (matchingDataObject) {
      matchingObjects.push(matchingDataObject)
    }
  }

  return matchingObjects
}

export const AVS_MESSAGES: any = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  AVS_INGESTION: 'AVS Ingestion',
  AVS_PUBLISH: 'AVS Publishing',
  AVS_DEPUBLISH: 'AVS De-Publish',
}

export const getColor = (status: string) => {
  return status === 'SUCCESS' ? '#4C6A0B' : status === 'FAILED' ? '#D4351C' : ''
}

export const getBGColor = (status: string) => {
  return status === 'SUCCESS' ? '#F4F9EA' : status === 'FAILED' ? '#FFDBD5' : ''
}

export const getStatusMessage = (avsStatus: string, avsAction: string) => {
  const statusMessage = AVS_MESSAGES[avsStatus] || ''
  const actionMessage = AVS_MESSAGES[avsAction] || ''
  return actionMessage && statusMessage ? `${actionMessage}: ${statusMessage}` : ''
}
export function handleResponse(apiResponse: any) {
  let messages: any[] = []
  let isError = false
  let responseCode = null
  function traverse(node: any) {
    if (!node || typeof node !== 'object') return
    for (const key in node) {
      if (key === 'message' && typeof node?.[key] === 'string') {
        messages?.push(node?.[key])
      } else if ((key === 'messages' || key === 'errors') && Array.isArray(node?.[key])) {
        messages = messages?.concat(node?.[key]?.filter((msg: any) => typeof msg === 'string'))
      } else if (
        (key?.toLowerCase() === 'responsecode' || key?.toLowerCase() === 'code') &&
        typeof node?.[key] === 'number'
      ) {
        responseCode = node?.[key]
      } else {
        traverse(node?.[key])
      }
    }
  }
  traverse(apiResponse)
  // Determine if it is an error response
  isError = apiResponse?.status === 'error' || apiResponse?.error === true || responseCode !== 200
  return {
    error: isError,
    responseCode: responseCode,
    message: messages?.join(' '),
  }
}
export function convertAndCheck(input: any) {
  // Attempt to convert the input to a number
  const num = parseFloat(input)
  // Check if the input is a valid number
  if (!isNaN(num) && input === num.toString()) {
    return {
      original: input,
      converted: num,
      type: 'number',
    }
  } else {
    return {
      original: input,
      converted: NaN,
      type: 'string',
    }
  }
}
export function findParameterName(objects: any[], fieldName: string) {
  // Iterate over each object in the array

  for (const obj of objects) {
    // Check if the field name matches the object's field property
    if (obj.fieldName === fieldName) {
      // Return the parameter name if a match is found
      return obj.parameterName
    }
  }
  // Return null if no match is found
  return ''
}
export const validValueOrEmptyString = (value: any) => {
  if (!value) {
    return ''
  }
  return value
}

export const validateNoSpace = (value: string) => {
  return value?.trim() !== '' || 'This Field can not be empty or just spaces'
}
/**
 * To get the uniqe array obj removes deuplicate value based on keys
 * @function removeDuplicates
 * @param arr Array of object from which duplicate value need to be removed
 * @param key the key of by which we need to remove duplicate data from array
 * @returns {array} returns unique array of object based on key.
 */
export function removeDuplicates(arr: TypeObject[], key: string) {
  const seenAssetTypes = new Set()
  const uniqueObjects: TypeObject[] = []

  arr.forEach((obj: TypeObject) => {
    const keyObj = obj[key]
    if (!seenAssetTypes.has(keyObj)) {
      seenAssetTypes.add(keyObj)
      uniqueObjects.push(obj)
    }
  })

  return uniqueObjects
}
export const nullValuesPresent = (data: any) => {
  return Object?.values(data)?.every((value) => value === null)
}

export const getAttrDefValidationName = (validationNameKey: string, validationsList: any) => {
  return (
    (Array.isArray(validationsList) ? validationsList : [])?.find(
      (item: any) => item?.functionName === validationNameKey,
    )?.displayName || 'NA'
  )
}
export const getAttrDefDataSourceName = (dataSourceKey: string, dataSourceList: any) => {
  return (
    (Array.isArray(dataSourceList) ? dataSourceList : [])?.find(
      (item: any) => item?.key === dataSourceKey,
    )?.value || 'NA'
  )
}
export const getAttrDefScopeName = (scopeValueKey: string, scopesList: any) => {
  return (
    (Array.isArray(scopesList) ? scopesList : [])?.find((item: any) => item?.key === scopeValueKey)
      ?.value || 'NA'
  )
}
export const getAttrDefSourceAttributeName = (sourceAttributeKey: string, attributesList: any) => {
  return (
    (Array.isArray(attributesList) ? attributesList : [])?.find(
      (item: any) => item?.fieldName === sourceAttributeKey,
    )?.label || 'NA'
  )
}
export const staticCheckToHideAsterisk = (element: any) => {
  return element.fieldName === 'class' && element.fieldType === 'SELECT'
}

export const groupAndSortBasedOnBlitzId = (data: Record<string, any>[]) => {
  // Step 1: Group by blitzId
  const grouped = data.reduce((acc: any, item: any) => {
    if (!acc[item.blitzId]) {
      acc[item.blitzId] = []
    }
    acc[item.blitzId].push(item)
    return acc
  }, {})

  // Define the order of statuses
  const statusOrder = ['DRAFT', 'PUBLISHED', 'SCHEDULE PUBLISHED']

  // Function to get the order index
  const getStatusOrderIndex = (status: string) => {
    const index = statusOrder.indexOf(status)
    return index === -1 ? statusOrder.length : index
  }

  // Step 2: Sort each group by status order
  for (const blitzId in grouped) {
    grouped[blitzId].sort((a: any, b: any) => {
      return getStatusOrderIndex(a.status) - getStatusOrderIndex(b.status)
    })
  }

  // Convert the grouped object back to an array of grouped arrays
  const groupedArray = Object.entries(grouped).map(([blitzId, items]) => ({
    blitzId: Number(blitzId),
    items: items,
  }))

  return groupedArray
}
export const isValidImageURL = (url: string) => {
  return /\.(jpeg|jpg|gif|png|webp)$/.test(url)
}
export const validateValue = (value: any, dataType: any) => {
  let error = ''
  switch (dataType) {
    case 'String':
      if (typeof value !== 'string') {
        error = 'Default value must be a string'
      }
      break
    case 'Number':
      if (isNaN(value)) {
        error = 'Default value must be a number'
      }
      break
    case 'Boolean':
      if (value !== 'true' && value !== 'false') {
        error = 'Default value must be a boolean (true/false)'
      }
      break
    case 'Date':
      if (isNaN(Date.parse(value))) {
        error = 'Default value must be a valid date'
      }
      break
    case 'File':
      error = 'Default value for files should be handled in file input fields'
      break
    case 'Image File':
    case 'ImageType':
      if (!isValidImageURL(value)) {
        error = 'Default value must be a valid image URL'
      }
      break
    case 'JSON':
      try {
        JSON.parse(value)
      } catch (e) {
        error = 'Default value must be a valid JSON'
      }
      break
    default:
      break
  }
  return error
}

export const replaceHyphensWithSpaces = (text: string) => {
  return text.replace(/-/g, ' ')
}

export const formatMasterKey = (key: string): string => {
  const parts: string[] = key?.split('/') || []
  const lastPart: string = parts[parts?.length - 1]
  return lastPart?.replace(/_/g, ' ')
}
export const createKeyValuePairs = (input: any) => {
  if (Array.isArray(input)) {
    return input.map((str: string) => ({ key: str, value: str }))
  } else if (typeof input === 'string') {
    return [{ key: input, value: input }]
  } else {
    throw new Error('Input must be either an array of strings or a single string')
  }
}

export const toCamelCase = (str: string) => {
  str = str
    ?.replace(/\s+/g, ' ')
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase(),
    )
    .replace(/\s+/g, '')
  return str
}

export const isFieldRequired = (required: string) => required.toLowerCase() === 'yes'

// export const getAllMasterAPIUrls = (widgetDefinition: any) => {
//   const { data: { automated: { formData = [] } = {} } = {} } = widgetDefinition || {}
//   const masterAPIUrlsArray: string[] = []
//   formData?.forEach(({ form = [] }) => {
//     form?.forEach((formField: any) => {
//       const { parameter: { master = '' } = {} } = formField || {}
//       if (master && master.includes('system-configurations')) {
//         master.trim()
//         const splittedUrl = master.split('/')
//         const keyName = `?keyName=${splittedUrl[splittedUrl.length - 1]}`
//         splittedUrl.splice(splittedUrl.length - 1, 1)
//         const finalUrl = `${splittedUrl.join('/')}${keyName}`
//         if (!masterAPIUrlsArray.includes(finalUrl)) masterAPIUrlsArray.push(finalUrl)
//       } else {
//         if (master && !masterAPIUrlsArray.includes(master)) masterAPIUrlsArray.push(master)
//       }
//     })
//   })
//   return masterAPIUrlsArray
// }

export const getCorrectMasterAPIUrl = (masterUrl: any) => {
  let finalUrl
  if (
    masterUrl &&
    (masterUrl.includes('system-configurations') || masterUrl.includes('blitz-master'))
  ) {
    masterUrl.trim()
    const splittedUrl = masterUrl.split('/')
    const keyName = `?keyName=${splittedUrl[splittedUrl.length - 1]}`
    splittedUrl.splice(splittedUrl.length - 1, 1)
    const finalUrl = `${splittedUrl.join('/')}${keyName}`
    return finalUrl
  }
  if (masterUrl) {
    return (finalUrl = masterUrl)
    return
  }
}

export const toCapitalCase = (str: string) => {
  if (str) {
    return str
      .split(' ')
      .map((word, index) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
      )
      .join('')
  }
  return '' as string
}

export const dashStrToCapitalCase = (str: string) => {
  if (str) {
    return str
      .split('_')
      .map((word, index) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
      )
      .join('')
  }
  return '' as string
}

export const keyExists = (obj: any, key: any) => {
  if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
    return false
  } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return true
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const result: any = keyExists(obj[i], key)
      if (result) {
        return result
      }
    }
  } else {
    for (const k in obj) {
      const result: any = keyExists(obj[k], key)
      if (result) {
        return result
      }
    }
  }

  return false
}

export const updateObject = (keyName: any, newVal: any, object: any) => {
  const results: any = {}
  for (const key in object) {
    if (key === keyName) {
      results[key] = newVal
    } else if (typeof object[key] === 'object' && object[key] !== null) {
      results[key] = updateObject(keyName, newVal, object[key])
    } else {
      results[key] = object[key]
    }
  }
  return results
}

export const checkIsSectionHasAddMore = (form: any[]) => {
  return form?.some((formField) => formField?.parameter?.addMore)
}

export const createDeepCopy = (object: any) => {
  return JSON.parse(JSON.stringify(object))
}

const getMasterOptionData = async (
  master = '',
  fieldName = '',
  contentCategoryValue = [] as any,
  assetCategoryValue = [] as any,
) => {
  if (master && fieldName) {
    const url = getCorrectMasterAPIUrl(master)
    const body = { contentCategoryValue, assetCategoryValue }
    const { payload: { data: { data = {}, responseCode = 0 } = {} } = {} } = await store.dispatch(
      getMastersData({ url, body }) as any,
    )
    if (responseCode === 200) {
      switch (fieldName) {
        case FORM_FIELD.CONTENT_CATEGORY:
          return data?.contentCategories || []
        case FORM_FIELD.ASSET_CATEGORY:
          return data?.assetCategories || []
        case FORM_FIELD.ASSET_TYPE:
          return data?.assetTypes || []
        default:
          if (master.includes('system-configurations')) {
            return data || []
          }
      }
    }
  }
  return []
}

const getOptionsListByContentCategoryValues = async (
  master: string,
  fieldName: string,
  widgetDetailData: any,
) => {
  const contentCategoryValues: any =
    (await getInputFormFieldValue(
      widgetDetailData,
      CATEGORY_LABEL.BLITZ_ASSET_CATEGORY,
      FORM_FIELD.CONTENT_CATEGORY,
    )) || {}
  const contentCategoryKeys: any[] = contentCategoryValues?.value?.map((ele: any) => ele?.key)
  const optionsListRes: any[] = await getMasterOptionData(master, fieldName, contentCategoryKeys)
  return optionsListRes || []
}

const getOptionsListByAssetCategoryValues = async (
  master: string,
  fieldName: string,
  widgetDetailData: any,
) => {
  const contentCategoryValues: any =
    (await getInputFormFieldValue(
      widgetDetailData,
      CATEGORY_LABEL.BLITZ_ASSET_CATEGORY,
      FORM_FIELD.CONTENT_CATEGORY,
    )) || {}
  const contentCategoryKeys = contentCategoryValues?.value?.map((ele: any) => ele?.key)
  const assetCategoryValues: any =
    (await getInputFormFieldValue(
      widgetDetailData,
      CATEGORY_LABEL.BLITZ_ASSET_CATEGORY,
      FORM_FIELD.ASSET_CATEGORY,
    )) || {}
  const assetCategoryKeys = assetCategoryValues?.value?.map((ele: any) => ele?.key)
  const optionsListRes = await getMasterOptionData(
    master,
    fieldName,
    contentCategoryKeys,
    assetCategoryKeys,
  )
  return optionsListRes || []
}

export const initializeInputFormUtil = async (
  formData = [] as any,
  pageType = '',
  widgetDetailData = {} as any,
) => {
  const {
    PAGE_TYPE: { UPDATE, EDIT, VIEW, CREATE },
  } = CONSTANTS
  const savedDataEngineType = widgetDetailData?.data?.engine || 'MANUAL'
  let inputFormKeysWithAddMoreValue: any = {}
  const finalPreparedInputFormArr: any = []
  for (let formDataIndex = 0; formDataIndex < formData?.length; formDataIndex++) {
    const { label = '', form = [] } = formData[formDataIndex]
    const tempCategoryObj: any = {
      isCategoryHasAddMore: checkIsSectionHasAddMore(form),
      categoryLabel: label,
      categoryData: [
        {
          categoryFormFields: [],
        },
      ],
    }
    for (let fieldIndex = 0; fieldIndex < form?.length; fieldIndex++) {
      const formField: any = createDeepCopy(form[fieldIndex])
      formField.parameter.fieldName = dashStrToCapitalCase(formField.parameter.fieldName)
      const { parameter: { master = '', fieldName = '' } = {} } = formField || {}
      const optionsList = await getMasterOptionData(master, fieldName)
      formField['options'] = optionsList
      const tempObj: any = {}
      tempCategoryObj?.isCategoryHasAddMore
        ? (tempObj[toCapitalCase(label)] = true)
        : (tempObj[dashStrToCapitalCase(fieldName)] = false)
      inputFormKeysWithAddMoreValue = { ...inputFormKeysWithAddMoreValue, ...tempObj }
      if (
        (pageType === UPDATE && savedDataEngineType !== 'MANUAL') ||
        ((pageType === EDIT || pageType === CREATE) && savedDataEngineType === 'MANUAL') ||
        ((pageType === EDIT || pageType === CREATE) && savedDataEngineType === '')
      ) {
        tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
      }
      if (
        (pageType === EDIT || pageType === VIEW) &&
        savedDataEngineType &&
        savedDataEngineType !== 'MANUAL'
      ) {
        const categoryLabel = toCapitalCase(label)
        if (fieldName === FORM_FIELD.ASSET_CATEGORY) {
          const optionsListRes: any[] = await getOptionsListByContentCategoryValues(
            master,
            fieldName,
            widgetDetailData,
          )
          formField['options'] = optionsListRes
          tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
        } else if (fieldName === FORM_FIELD.ASSET_TYPE) {
          const optionsListRes: any[] = await getOptionsListByAssetCategoryValues(
            master,
            fieldName,
            widgetDetailData,
          )
          formField['options'] = optionsListRes
          tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
        } else if (categoryLabel === CATEGORY_LABEL.RANGE_BY_BLITZ_ATTRIBUTE) {
          const lengthOfCategory = widgetDetailData?.data?.query[categoryLabel].length
          if (lengthOfCategory > 1) {
            if (fieldIndex === 0) {
              tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
              tempCategoryObj.categoryData[0].categoryFormFields.push(form[1])
              tempCategoryObj.categoryData[0].categoryFormFields.push(form[2])
              for (let tempI = 1; tempI < lengthOfCategory; tempI++) {
                const categoryFormArr = []
                categoryFormArr.push(formField)
                categoryFormArr.push(form[1])
                categoryFormArr.push(form[2])
                tempCategoryObj.categoryData.push({
                  categoryFormFields: categoryFormArr,
                })
              }
            }
          } else {
            tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
          }
        } else if (
          [
            CATEGORY_LABEL.SELECT_BY_BLITZ_ATTRIBUTE,
            CATEGORY_LABEL.EXCLUDE_BY_BLITZ_ATTRIBUTE,
          ].includes(categoryLabel)
        ) {
          const lengthOfCategory = widgetDetailData?.data?.query[categoryLabel].length
          if (lengthOfCategory > 1) {
            tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
            for (let tempI = 1; tempI < lengthOfCategory; tempI++) {
              const categoryFormArr = []
              categoryFormArr.push(formField)
              tempCategoryObj.categoryData.push({
                categoryFormFields: categoryFormArr,
              })
            }
            for (let tempI = 0; tempI < tempCategoryObj.categoryData.length; tempI++) {
              const { newObj }: any =
                (await getInputFormFieldValueByIndex(widgetDetailData, label, tempI)) || {}
              tempCategoryObj.categoryData[tempI].categoryFormFields[1] = newObj
            }
          } else {
            const { newObj }: any =
              (await getInputFormFieldValue(widgetDetailData, categoryLabel, fieldName)) || {}
            if (isValidObject(newObj) && fieldName === FORM_FIELD.BLITZ_ATTRIBUTES) {
              tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
              tempCategoryObj.categoryData[0].categoryFormFields[1] = newObj
            }
          }
        } else {
          tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
        }
      }
    }
    finalPreparedInputFormArr.push(tempCategoryObj)
  }
  return {
    inputFormKeysWithAddMoreValue,
    finalPreparedInputFormArr,
  }
}

export const getInputFormFieldValueByIndex = async (
  widgetDetailData = {} as any,
  categoryLabel = '',
  index = -1,
) => {
  categoryLabel = toCapitalCase(categoryLabel)
  const { data: { query = {} } = {} } = widgetDetailData || {}
  const formFieldArrByCategory = query[categoryLabel]
  const { parameterId = '', attributeKey = '' } = formFieldArrByCategory[index] || {}
  const newObj: any = await getNewFieldObjForBlitzAttributeChange(parameterId)
  const fieldValue = getFieldValueObj(attributeKey, newObj)
  return fieldValue
}

export const getFieldValueByCategoryIndexnFieldName = async (
  widgetDetailData = {} as any,
  categoryLabel = '',
  fieldName = '',
  index = -1,
) => {
  const { data: { query = {} } = {} } = widgetDetailData || {}
  const formFieldArrByCategory = query[categoryLabel]
  const { rangeKey = '', attributeKey = '', attributeValue } = formFieldArrByCategory?.[index] || {}
  if (fieldName === FORM_FIELD.RANGE_BY) {
    const fieldValue = getFieldValueObj(rangeKey, {})
    return fieldValue
  }
  if (fieldName === FORM_FIELD.BLITZ_ATTRIBUTES) {
    const fieldValue = getFieldValueObj(attributeKey, {})
    return fieldValue
  }
  if (
    [CATEGORY_LABEL.SELECT_BY_BLITZ_ATTRIBUTE, CATEGORY_LABEL.EXCLUDE_BY_BLITZ_ATTRIBUTE].includes(
      categoryLabel,
    )
  ) {
    const fieldValue = getFieldValueObj(attributeValue, {})
    return fieldValue
  }
  const fieldValue = getFieldValueObj(formFieldArrByCategory?.[index]?.[fieldName], {})
  return fieldValue
}

export const getInputFormFieldValue = async (
  widgetDetailData = {} as any,
  categoryLabel = '',
  fieldNameFromInputForm = '',
) => {
  let fieldValue: any
  if (isValidObject(widgetDetailData) && isValidObject(widgetDetailData?.data?.query)) {
    const { data: { query = {} } = {} } = widgetDetailData || {}
    const formFieldByFieldName = query[fieldNameFromInputForm]
    if (isValidObject(formFieldByFieldName) && !Array.isArray(formFieldByFieldName)) {
      const { value }: any = formFieldByFieldName || {}
      if (typeof value === 'string') {
        fieldValue = getFieldValueObj(value)
        return fieldValue
      }
    }
    if (formFieldByFieldName === undefined || Array.isArray(formFieldByFieldName)) {
      const formFieldArrByCategory = query[categoryLabel]
      if (Array.isArray(formFieldArrByCategory)) {
        for (
          let categoryFieldIndex = 0;
          categoryFieldIndex < formFieldArrByCategory.length;
          categoryFieldIndex++
        ) {
          const {
            parameterId = '',
            dataType,
            fieldName = '',
            value,
            attributeKey = '',
            attributeValue,
            rangeKey = '',
          } = formFieldArrByCategory[categoryFieldIndex] || {}
          if (fieldNameFromInputForm === fieldName) {
            if (
              fieldNameFromInputForm !== FORM_FIELD.RANGE_BY &&
              fieldNameFromInputForm !== FORM_FIELD.BLITZ_ATTRIBUTES
            ) {
              if (typeof value === 'object' && Array.isArray(value)) {
                fieldValue = getFieldValueObj(value)
                break
              }
              if (typeof value === 'string' && dataType === 'Number' && !Array.isArray(value)) {
                fieldValue = getFieldValueObj(value)
                break
              }
              if (typeof value === 'string' && !Array.isArray(value)) {
                fieldValue = getFieldValueObj(value)
                break
              }
            }
            if (fieldNameFromInputForm === FORM_FIELD.BLITZ_ATTRIBUTES) {
              if (parameterId) {
                const newObj: any = await getNewFieldObjForBlitzAttributeChange(parameterId)
                fieldValue = getFieldValueObj(attributeKey, newObj)
              } else {
                fieldValue = getFieldValueObj(attributeKey)
              }
              break
            }
            if (fieldNameFromInputForm === FORM_FIELD.RANGE_BY) {
              fieldValue = getFieldValueObj(rangeKey)
              break
            }
          }
          if (categoryLabel === CATEGORY_LABEL.RANGE_BY_BLITZ_ATTRIBUTE) {
            const firstObj = formFieldArrByCategory[categoryFieldIndex]
            fieldValue = {
              value: firstObj[fieldNameFromInputForm],
              newObj: {},
            }
            break
          }
          if (
            [
              CATEGORY_LABEL.SELECT_BY_BLITZ_ATTRIBUTE,
              CATEGORY_LABEL.EXCLUDE_BY_BLITZ_ATTRIBUTE,
            ].includes(categoryLabel)
          ) {
            if (attributeKey === fieldNameFromInputForm) {
              fieldValue = getFieldValueObj(attributeValue)
              break
            }
          }
        }
      }
    }
  }
  return fieldValue
}

export const getNewFieldObjForBlitzAttributeChange = async (additionalField: any) => {
  let newObj = {}
  if (additionalField) {
    const { payload: { responseCode = 0, data = {} } = {} }: any = await store.dispatch(
      getParameterRepositoryDetail(
        typeof additionalField === 'object' ? additionalField._id : additionalField,
      ) as any,
    )
    if (responseCode === 200) {
      const { master = '', fieldType = '' } = data || {}
      if (master && fieldType === 'Select') {
        const masterUrl = getCorrectMasterAPIUrl(master)
        const masterRes = await store.dispatch(getMastersData({ url: masterUrl, body: {} }) as any)
        if (masterRes?.payload?.data?.responseCode === 200) {
          newObj = {
            required: 'No',
            grid: 6,
            validations: [],
            conditions: [],
            parameterRepositoryId: additionalField,
            options: masterRes?.payload?.data?.data || [],
            parameter: data,
          }
        }
      }
      if (!master && fieldType !== 'Select') {
        newObj = {
          required: 'No',
          grid: 6,
          validations: [],
          conditions: [],
          parameterRepositoryId: additionalField,
          parameter: data,
        }
      }
    }
  }
  return newObj
}

const getFieldValueObj = (fieldValue: any, newObj = {}) => {
  return {
    value: fieldValue,
    newObj: isValidObject(newObj) ? newObj : {},
  }
}

export const convertURL = (url: string) => {
  return `${CONSTANTS.CLOUDFRONT_BASE_URL}${url.slice(CONSTANTS.S3_BUCKET_BASE_URL?.length)}`
}
export function getKeyByType(item: any, type: 'id' | 'title'): string {
  if (!item || typeof item !== 'object') {
    return ''
  }
  const keysMap: { [key: string]: string[] } = {
    title: ['assetTitle', 'widgetTitle', 'containerTitle', 'title'],
    id: ['assetId', 'widgetId', 'containerId', 'contentId', 'id'],
  }

  const keysToCheck = keysMap[type]
  if (!keysToCheck) {
    return ''
  }

  for (const key of keysToCheck) {
    if (item[key]) {
      return key
    }
  }
  return ''
}
export const prepareDefaultDataObject = async (
  finalPreparedInputFormArr = [],
  widgetDetailData = {},
) => {
  let defaultDataTempObj: any = {}
  for (let formDataIndex = 0; formDataIndex < finalPreparedInputFormArr?.length; formDataIndex++) {
    const {
      isCategoryHasAddMore = false,
      categoryLabel = '',
      categoryData = [],
    } = finalPreparedInputFormArr[formDataIndex]
    const categoryLabelKey = toCapitalCase(categoryLabel)
    const tempObj: any = {}
    if (isCategoryHasAddMore) {
      tempObj[categoryLabelKey] = []
    }
    for (let categoryFormIndex = 0; categoryFormIndex < categoryData?.length; categoryFormIndex++) {
      const { categoryFormFields = [] } = categoryData[categoryFormIndex]
      for (let formFieldIndex = 0; formFieldIndex < categoryFormFields?.length; formFieldIndex++) {
        const formField = categoryFormFields[formFieldIndex]
        const { parameter: { fieldName = '' } = {} } = formField || {}
        let fieldValue: any
        if (
          [
            CATEGORY_LABEL.SELECT_BY_BLITZ_ATTRIBUTE,
            CATEGORY_LABEL.EXCLUDE_BY_BLITZ_ATTRIBUTE,
          ].includes(categoryLabelKey)
        ) {
          if (formFieldIndex === 0) {
            const { value }: any =
              (await getInputFormFieldValueByIndex(
                widgetDetailData,
                categoryLabel,
                categoryFormIndex,
              )) || {}
            fieldValue = value
          } else {
            const { value }: any =
              (await getInputFormFieldValue(widgetDetailData, categoryLabelKey, fieldName)) || {}
            fieldValue = value
          }
        } else if (categoryLabelKey === CATEGORY_LABEL.RANGE_BY_BLITZ_ATTRIBUTE) {
          const { value }: any =
            (await getFieldValueByCategoryIndexnFieldName(
              widgetDetailData,
              categoryLabelKey,
              fieldName,
              categoryFormIndex,
            )) || {}
          fieldValue = value
        } else {
          const { value }: any =
            (await getInputFormFieldValue(widgetDetailData, categoryLabelKey, fieldName)) || {}
          fieldValue = value
        }
        if (isCategoryHasAddMore) {
          tempObj[categoryLabelKey][categoryFormIndex] = {
            ...tempObj[categoryLabelKey][categoryFormIndex],
            [fieldName]: fieldValue,
          }
        } else {
          tempObj[fieldName] = fieldValue
        }
        defaultDataTempObj = { ...defaultDataTempObj, ...tempObj }
      }
    }
  }
  return defaultDataTempObj
}

export const debounceFunction = (func: any, timeout = 500) => {
  let timer: any
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function cleanObject(obj: { [key: string]: any }) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined && v !== null))
}

// Helper function to extract dynamic keys
export function extractDynamicData(prefix: string, originalData: any) {
  const dynamicData: any[] = []
  const regex = new RegExp(`^${prefix}_\\d+_(.+)$`)
  Object.keys(originalData).forEach((key) => {
    const match = key.match(regex)
    if (match) {
      const index = parseInt(key.split('_')[1], 10)
      const attribute = match[1]
      if (!dynamicData[index]) dynamicData[index] = {}
      dynamicData[index][attribute] = originalData[key]
    }
  })
  return dynamicData.map(cleanObject).filter((item: any) => Object.keys(item).length === 2)
}

export const greatestCommonDivisor = (firstNumber: number, secondNumber: number): number => {
  return secondNumber === 0
    ? firstNumber
    : greatestCommonDivisor(secondNumber, firstNumber % secondNumber)
}

export const calculateAspectRatio = (width: number, height: number): string => {
  const divisor = greatestCommonDivisor(width, height)
  return `${width / divisor}:${height / divisor}`
}

export const isWithinTolerance = (
  width: number,
  height: number,
  validWidth: number,
  validHeight: number,
) => {
  // Calculate the 10% tolerance range for width
  const minWidth = validWidth * 0.9
  const maxWidth = validWidth * 1.1

  // Calculate the 10% tolerance range for height
  const minHeight = validHeight * 0.9
  const maxHeight = validHeight * 1.1

  // Check if both width and height are within the tolerance range
  const isWidthValid = width >= minWidth && width <= maxWidth
  const isHeightValid = height >= minHeight && height <= maxHeight

  return isWidthValid && isHeightValid
}

export const getFormattedValue = (data: any) => {
  let result = 'NA'
  if (data?.parameterId && data?.value) {
    switch (data?.parameterId?.fieldType?.toLowerCase()) {
      case 'time':
        result = getDateInFormat(data?.value, CONSTANTS.TIME_FORMAT_3)
        break

      case 'year':
        result = getDateInFormat(data?.value, CONSTANTS.DATE_YEAR_FORMAT)
        break

      case 'datetime':
        result = getDateInFormat(data?.value, CONSTANTS.DATE_FORMAT_TIME)
        break

      case 'date':
        result = getDateInFormat(data?.value, CONSTANTS.DATE_FORMAT)
        break

      default:
        result = data?.value
        break
    }
  }

  return result
}

export const openLinkInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export const validateField = (value: string, validations: any[], fieldName: string) => {
  for (const validation of validations) {
    switch (validation.functionName) {
      case FieldFunctions.EQUALS: {
        // Add logic for equals validation
        break
      }
      case FieldFunctions.ALPHA: {
        const regex = new RegExp(validation.customValue)
        if (!regex.test(value)) {
          return `${fieldName} must be alphabetic`
        }
        break
      }
      case FieldFunctions.ALPHANUM: {
        const regex = /^[a-zA-Z0-9]+$/
        if (!regex.test(value)) {
          return `${fieldName} must be alphanumeric`
        }
        break
      }
      case FieldFunctions.ALNUM_WITH_SPECIAL_CHARS: {
        const regex = new RegExp(validation.customValue)
        if (!regex.test(value)) {
          return `${fieldName} must be alphanumeric with special characters`
        }
        break
      }
      case FieldFunctions.MAX:
        if (value.length > parseInt(validation.customValue, 10)) {
          return `${fieldName} should not exceed ${validation.customValue} characters.`
        }
        break
      case FieldFunctions.MIN: {
        if (value.length < parseInt(validation.customValue, 10)) {
          return `${fieldName} should be at least ${validation.customValue} characters.`
        }
        break
      }
      case FieldFunctions.PATTERN: {
        const regex = new RegExp(validation.customValue)
        if (!regex.test(value)) {
          return `${fieldName} is invalid.`
        }
        break
      }
      case FieldFunctions.URI: {
        const regex = new RegExp(
          '^(https?:\\/\\/|http:\\/\\/)?' + // protocol (http or https optional)
            '((([a-z0-9](?:[a-z0-9-]*[a-z0-9])?)\\.)+[a-z]{2,}|' + // domain name
            'localhost|' + // localhost
            '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IP address
            '\\[?[a-f0-9]*:[a-f0-9:%.]*\\]?)' + // IPv6
            '(\\:\\d+)?(\\/[-a-z0-9+&@#/%=~_|$?!:.]*[a-z0-9+&@#/%=~_|$])?$', // path
          'i',
        )
        if (!regex.test(value)) {
          return `${fieldName} is invalid.`
        }
        break
      }
      case FieldFunctions.DATE_GREATER_THAN: {
        // Add logic for date greater than validation
        break
      }
      case FieldFunctions.DATE_LESS_THAN: {
        // Add logic for date less than validation
        break
      }
      case FieldFunctions.DATE_LESS_THAN_EQUALS: {
        // Add logic for date less than or equals validation
        break
      }
      case FieldFunctions.DATE_GREATER_THAN_EQUALS: {
        // Add logic for date greater than or equals validation
        break
      }
      case FieldFunctions.ISO: {
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z$/ // ISO date format
        if (!regex.test(value)) {
          return `${validation.displayName} is invalid.`
        }
        break
      }
      case FieldFunctions.VALIDATE_ASPECT_RATIO: {
        // Add logic for aspect ratio validation
        break
      }
      case FieldFunctions.VALIDATE_FILE_TYPE: {
        // Add logic for file type validation
        break
      }
      case FieldFunctions.VALIDATE_RESOLUTION: {
        // Add logic for resolution validation
        break
      }
      case FieldFunctions.COUNTRY_FOR_RATINGS: {
        // Add logic for country for ratings validation
        break
      }
      case FieldFunctions.LIMIT: {
        // Add logic for limit validation
        break
      }
      // Add more validation cases as needed
      default:
        break
    }
  }
  return true
}

const formatErrorMessage = (message: string) => {
  return message.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())
}

export const setFormErrors = (errors: any, setError: any) => {
  let imageErrors = null
  errors.forEach((error: string) => {
    const errorData = error
      .split('"')
      .filter(Boolean)
      .filter((item: string) => item !== ' ' && item)
    const fieldNameData = errorData[0].split('.')
    let fieldName = ''
    const message = errorData[errorData.length - 1]

    if (fieldNameData.length > 1) {
      const lastElement = fieldNameData[fieldNameData.length - 1]
      const secondLastElement = fieldNameData[fieldNameData.length - 2]

      if (secondLastElement.includes('[') && secondLastElement.includes(']')) {
        fieldName = `${secondLastElement}.${lastElement}`
      } else {
        fieldName = lastElement
      }
    } else {
      fieldName = fieldNameData[0]
    }

    let formattedString = formatErrorMessage(fieldName)
    if (fieldName.includes('].')) {
      const splitString = fieldName.split('].')[1]
      formattedString = formatErrorMessage(splitString)
    }
    const errorMessage = formattedString + ' ' + message.trim()
    if (setError === 'images') {
      imageErrors = {
        fieldName,
        errorMessage,
      }
    } else {
      setError(fieldName, {
        type: 'manual',
        message: errorMessage,
      })
    }
  })

  if (imageErrors) {
    return imageErrors
  }
}
// Utility function to dynamically fetch ID, title, type, or other fields
export const getDynamicRowValue = (data: any, fieldType: string) => {
  // Define regular expressions for different field types
  const regexMappings: { [key: string]: RegExp } = {
    id: /^(assetId|widgetId|containerId|contentId|id)$/,
    title: /^(assetTitle|widgetTitle|containerTitle|contentTitle|title)$/,
    type: /^(assetType|widgetType|containerType|contentType|type)$/,
    // You can add more categories if needed
  }
  // If the fieldType exists in the mapping, use the corresponding regex
  const regex = regexMappings[fieldType.toLowerCase()]
  if (!regex) {
    console.error(`Unsupported fieldType: ${fieldType}`)
    return ''
  }
  // Iterate over the data's keys and return the matching value
  for (const key of Object.keys(data)) {
    if (regex.test(key) && data[key]) {
      return data[key]
    }
  }
  // Return an empty string if no match is found
  return ''
}

export const mergeFieldArrays = (firstArr: any[], secondArr: any[]) => {
  if (secondArr.length === 0) return firstArr
  // Flatten firstArr for easier comparison
  const flatFirstArr = firstArr.flat()

  // Function to find if an object from firstArr exists in secondArr by fieldName
  const findByFieldName = (arr: any[], fieldName: string) =>
    arr.find((item: any) => item.fieldName === fieldName)

  // Iterate through each array in secondArr
  return secondArr.map((secondSubArr) => {
    // Create a new array that contains all the items from secondSubArr
    const updatedSubArr: any[] = []
    // Check for missing objects from firstArr and add them with default values if necessary
    flatFirstArr.forEach((firstItem) => {
      const existingItem = findByFieldName(secondSubArr, firstItem.fieldName)
      if (existingItem) {
        // If the object exists in secondArr, add it in the correct order
        updatedSubArr.push(existingItem)
      } else {
        // If the object is missing, add it with default values from firstArr
        updatedSubArr.push({
          title: firstItem.title,
          value: firstItem.value, // Using default value from firstArr
          setValue: firstItem.setValue, // Using default setValue from firstArr
          fieldName: firstItem.fieldName,
        })
      }
    })

    return updatedSubArr
  })
}

export const getFieldGridValues = (data: any) => {
  if (!data) return {}

  return data
    ?.map((item: any) => {
      if (Array.isArray(item)) {
        // Merging all objects within the sub-array into one object
        return item.reduce((acc: any, subItem: any) => {
          return {
            ...acc,
            [subItem?.parameter?.fieldName]: subItem?.grid === 12 ? 100 : 50,
          }
        }, {})
      } else if (item?.parameter?.fieldName) {
        return {
          [item?.parameter?.fieldName]: item?.grid === 12 ? 100 : 50,
        }
      }
    })
    .reduce((acc: any, curr: any) => {
      return { ...acc, ...curr }
    }, {})
}
